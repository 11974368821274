import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrFranklinLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Franklin"
    institution="Asheville Urological Associates"
    referralCode="DRFRANKLIN"
  />
)

export default DrFranklinLandingPage
